
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ScoreRange } from 'shared-entities'

@Component
export default class PointDistributionTable extends Vue {
  @Prop({ type: Boolean, required: true })
  loading!: boolean

  @Prop({ required: true })
  scoreDistribution!: ScoreRange[]
}
