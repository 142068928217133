
import Vue from 'vue'
import Component from 'vue-class-component'
import UiUtils from '../../util/ui-utils'
import PoolDataRepository, { PoolDataModel } from '@/data/repository/pool-data.repository'
import { PoolData } from 'shared-entities'

import PointDistributionTable from './PointDistributionTable.vue'
import PreviousRoundsTable from './PreviousRoundsTable.vue'

@Component({
  head: {
    title: UiUtils.translateTitle('label.rounds'),
  },
  components: {
    PointDistributionTable,
    PreviousRoundsTable,
  },
})
export default class RoundsScreen extends Vue {
  currentCurvePoint = ''
  isLoading = true
  poolData: PoolData | null = null
  subscriberId: number | null = null

  mounted() {
    this.subscriberId = PoolDataRepository.subscribeToPoolData(this.onPoolDataUpdated)
  }

  beforeDestroy() {
    if (this.subscriberId) {
      PoolDataRepository.unsubscribeFromPoolData(this.subscriberId)
    }
  }

  private onPoolDataUpdated(poolData: PoolDataModel | null) {
    this.poolData = poolData
    if (poolData) {
      this.isLoading = false
      this.currentCurvePoint =
        poolData.latestRound.lowestScore + (poolData.latestRound.program ? '*' : '')
    }
  }
}
