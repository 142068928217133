
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { InvitationRound } from 'shared-entities'

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          noProgram: 'No program specified',
        },
      },
    },
  },
})
export default class PreviousRoundsTable extends Vue {
  @Prop({ type: Boolean, required: true })
  loading!: boolean
  @Prop({ required: true })
  rounds!: InvitationRound[]

  get hasProgramsSpecified(): boolean {
    return this.rounds.some(it => !!it.program)
  }
}
